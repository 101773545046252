import React, { useEffect, useRef, useState } from "react"
import banner from "../images/aboutus/banner.png"
import { useMediaQuery } from "react-responsive"
import { findContentByLabel } from "../utils/findContentByLabel"
import { FormDatatext } from "../hooks/useFormatDatatext"

const Disclaimer = ({ data }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" })
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 996px)" })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const content = findContentByLabel(data, "disclaimer")
  return (
    <div className="loanProduct-container">
      <div
        className="normal-banner"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <h1 style={{ color: "white", fontWeight: "600" }}>免責聲明</h1>
      </div>
      <div className="policy-container">{FormDatatext(content)}</div>
    </div>
  )
}

export default Disclaimer
