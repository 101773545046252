import React, { Fragment } from "react"
import styles from "./css/textWithLargerNumbers.module.css"

export const TextWithLargerNumbers = ({ text }) => {
  const renderTextWithLargeNumbers = (text) => {
    const regex = /(\d+[,.\d]*)(\D*)/g
    let match
    const elements = []
    let lastIndex = 0

    if (text) {
      while ((match = regex.exec(text)) !== null) {
        const number = match[1]
        const nonNumberText = match[2]

        // Add text before the match
        if (match.index > lastIndex) {
          elements.push(
            <Fragment key={`text-${lastIndex}`}>
              {text.slice(lastIndex, match.index)}
            </Fragment>
          )
        }

        elements.push(
          <Fragment key={`number-${match.index}`}>
            <span className={styles.text1}>{number}</span>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              {nonNumberText}
            </span>
          </Fragment>
        )

        lastIndex = regex.lastIndex
      }

      // Add remaining text after the last match
      if (lastIndex < text.length) {
        elements.push(
          <Fragment key={`text-${lastIndex}`}>{text.slice(lastIndex)}</Fragment>
        )
      }

      return elements
    }
  }

  return <div>{renderTextWithLargeNumbers(text)}</div>
}
