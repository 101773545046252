/* global dataLayer */
import css from "./css/thankyou.module.css"
import banner from "../images/aboutus/banner.png"
import { useEffect } from "react"
// import GoogleAdsConversion from "../compoients/GoogleAdsConversion"

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className={css.container}>
      <div
        className="normal-banner"
        style={{ backgroundImage: `url(${banner}` }}
      >
        <h1 style={{ color: "white", marginTop: "-2rem" }}>Thank You!</h1>
      </div>
      <div className={`${css.content} color6`}>
        <h4>歡迎閣下申請 Moving up 貸款</h4>
        <h2>恭喜閣下已成功遞交申請，我們會盡快通知您！</h2>
      </div>
    </div>
  )
}

export default Thankyou
