import css from "./css/calculator.module.css"

import { useState, useEffect } from "react"
import CalculatorSlider from "./CalculatorSlider"
import money_icon from "../images/home/money.png"
import monthlybill_icon from "../images/home/Monthlybill.png"
import period_icon from "../images/home/period.png"
import { ApplyBtn } from "./Btn"
import { useNavigate } from "react-router-dom"
import { Paths } from "../utils/Paths"

import { findContentByLabel } from "../utils/findContentByLabel"
import { useQuery } from "@apollo/client"
import { wrapText } from "../utils/wrapText"
import { GET_HOMES } from "../graphql/Home/HOME_GQL"
import { loanCalculator } from "../utils/loanCalculation"
import { formatMoneyDisplay } from "../utils/formatMoneyDisplay"
const Calculator = () => {
  const [principal, setPrincipal] = useState(50000)
  const [period, setPeriod] = useState(12)
  const [minMonthlyPay, setMinMonthlyPay] = useState(0)
  const [maxMonthlyPay, setMaxMonthlyPay] = useState(0)

  let ContentData = useQuery(GET_HOMES)
  ContentData = ContentData?.data?.homes

  const calculator_remarks =
    findContentByLabel(ContentData, "calculator_remarks") || ""

  const navigate = useNavigate()

  const HandleAmount = (value) => {
    setPrincipal(value)
  }

  useEffect(() => {
    const rs = loanCalculator(principal, 10, 28, period)

    setMinMonthlyPay(rs.minMonthlyPay)
    setMaxMonthlyPay(rs.maxMonthlyPay)
  }, [principal, period, minMonthlyPay, maxMonthlyPay])

  return (
    <div className={css.container}>
      <div className={css.box_container}>
        <div className={css.row_container}>
          <div className={css.item_title}>
            <span>貸款金額</span>
          </div>
          <div className={css.money_input}>
            <span>HK $</span>
            <input
              type="text"
              value={principal === 0 ? "" : principal}
              style={{ color: "#0d8385" }}
              onChange={(e) => {
                setPrincipal(e.target.value)
              }}
            />
          </div>
        </div>
        <CalculatorSlider
          max_value={10000000}
          min_value={1000}
          step_value={1000}
          setChangedValue={HandleAmount}
          inputChanged={principal}
          sliderColor={"#2ccfd2"}
          minLabel={"1,000"}
          maxLabel={"10,000,000"}
        />
        <div className={css.row_container}>
          <div className={css.item_title}>
            <span>供款期數</span>
          </div>
          <div className={css.money_input}>
            <input
              type="text"
              value={period === 0 ? "" : period}
              style={{ color: "#0d8385" }}
              onChange={(e) => {
                setPeriod(e.target.value)
              }}
            />
            <span className="ps-5">Month</span>
          </div>
        </div>
        <CalculatorSlider
          max_value={360}
          min_value={1}
          step_value={1}
          setChangedValue={setPeriod}
          inputChanged={period}
          sliderColor={"#2ccfd2"}
          minLabel={"1個月"}
          maxLabel={"360個月"}
        />
        <div className={css.row_container} style={{ marginBottom: "0px" }}>
          <span>每月供款：</span>
          <div>
            <span style={{ color: "#0d8385", fontWeight: "700" }}>HK$</span>
            &nbsp;
            <span
              style={{
                color: "#0d8385",
                fontWeight: "700",
                fontSize: "1.8rem",
              }}
            >
              {formatMoneyDisplay(minMonthlyPay)} -{" "}
              {formatMoneyDisplay(maxMonthlyPay)}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-center pt-2">
          <button
            className={`applyBtn-lg  color-blue animatedBtn`}
            onClick={() => navigate(Paths.userLogin)}
          >
            立即申請
          </button>
        </div>
        <div className={css.remarks}>{wrapText(calculator_remarks)}</div>
      </div>
    </div>
  )
}

export default Calculator
