// import ReactChatbot from "./chatbot/ReactChatbot"
import "./css/floatBtnlist.css"
import FbBtn from "./btns/FbBtn"
import PhoneBtn from "./btns/PhoneBtn"
import WhatsAppBtn from "./btns/WhatsAppBtn"
import { useLocation } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { GET_METAS } from "../graphql/Meta/META_GQL"
import { findContentByLabel } from "../utils/findContentByLabel"

const FloatBtnList = () => {
  const location = useLocation()
  const { loading, error, data } = useQuery(GET_METAS)
  const [metasData, setMetasData] = useState([])
  useEffect(() => {
    if (data && data.metas) {
      setMetasData(data.metas)
    }
  }, [data])

  const hotline = findContentByLabel(metasData, "hotline")
  const whatsappNum = findContentByLabel(metasData, "whatsappNum")
  const facebookLink = findContentByLabel(metasData, "facebookLink")

  const blockLocation = ["/dashboard", "/admin", "/user"]

  const isPathBlocked = (pathname) => {
    return blockLocation.some((blockedPath) => pathname.startsWith(blockedPath))
  }
  return (
    <>
      {!isPathBlocked(location.pathname) && (
        <div className="floatBtnList">
          <div className="floatBtnList-item">
            <PhoneBtn phone={hotline} />
          </div>
          <div className="floatBtnList-item">
            <WhatsAppBtn phone={whatsappNum} />
          </div>
          <div className="floatBtnList-item">
            <FbBtn fblink={facebookLink} />
          </div>
          {/* <div className="floatBtnList-item">
        <ReactChatbot />
      </div> */}
        </div>
      )}
    </>
  )
}

export default FloatBtnList
