import React, { useContext, useState } from "react"
import { Box, Button, TextField } from "@mui/material"
import styles from "./css/adminLogin.module.css"
import bgfont from "../images/applyForm/bg-fonts.png"
import { AuthContext } from "../AuthContext"
import { useNavigate } from "react-router-dom"
import Profile from "../signature/components/Profile/Profile"
import useLogin from "../hooks/useLogin" // Adjust the import path as needed
import { userRole } from "../utils/UserRole"
import { Paths } from "../utils/Paths"
import { useMediaQuery } from "react-responsive"
import Cookies from "js-cookie"
import useDeviceQueries from "../hooks/useDeviceQueries"

const AdminLogin = () => {
  const { isDesktop, isTablet, isMobile } = useDeviceQueries()

  const { dispatch } = useContext(AuthContext)
  const [idCard, setIdCard] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  let navigate = useNavigate()

  const { login, loading, error: loginError } = useLogin()

  const handleLogin = async (e) => {
    e.preventDefault()
    const rs = await login(idCard, password)

    if (rs.success) {
      localStorage.setItem("isAdmin", JSON.stringify(true))
      localStorage.setItem("userRole", JSON.stringify(userRole.admin))

      dispatch({
        type: "LOGIN",
        payload: {
          userRole: userRole.admin,
        },
      })
      // Set a cookie with a 10-hour expiration
      Cookies.set("keystonejs-session", "true", { expires: 10 / 24 })
      setTimeout(() => {
        navigate(Paths.admin)
      }, 1000)
    } else {
      setError(loginError || "帳戶輸入錯誤")
    }
  }

  return (
    <div>
      <Profile back_url={""} logoLink="/login" currentpage={"login"} />
      <Box
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
              backgroundColor: "#ffffff99",
              borderRadius: "12px",
            },
            "& .MuiTypography-root": {
              color: "unset",
            },
            "& input": {
              color: "#575757",
              zIndex: 99,
              fontSize: "18px",
            },
            "& .MuiSelect-select": {
              color: "#575757",
              fontSize: "18px",
              zIndex: 99,
            },
          },
        }}
        className={styles.container}
      >
        {!isMobile && (
          <Box className={styles.leftSection}>
            <img src={bgfont} alt="bg-font" />
          </Box>
        )}
        <div className={styles.formSection}>
          <div className={styles.formInnerBox}>
            <div className={`${styles.loginTab}`} style={{ color: "#108d91" }}>
              管理員登入
            </div>
            <TextField
              className={styles.customTextField}
              label="管理員帳號"
              variant="outlined"
              type="text"
              id="idCard"
              value={idCard}
              onChange={(e) => setIdCard(e.target.value)}
              fullWidth
            />
            <TextField
              className={styles.customTextField}
              label="密碼"
              variant="outlined"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
            />
            <Button
              className={styles.customButton}
              variant="contained"
              onClick={handleLogin}
              sx={{
                mt: 2,
              }}
              disabled={loading}
            >
              登入
            </Button>
            {error && <p className={styles.error_msg}>{error}</p>}
          </div>
        </div>
      </Box>
    </div>
  )
}

export default AdminLogin
