import { gql } from "@apollo/client"

export const GET_USERS = gql`
  query Users {
    users {
      id
      name
      email
      isAdmin
      showCMS
      showEsignature
      postsCount
      createdAt
    }
  }
`
export const CREATE_USER = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      name
      email
      isAdmin
      isSuperAdmin
      showCMS
      postsCount
      createdAt
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
    updateUser(where: $where, data: $data) {
      id
      name
      email
      isAdmin
      showCMS
      showEsignature
      postsCount
      createdAt
    }
  }
`
export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
      name
      email
      isAdmin
      showCMS
      showEsignature
      postsCount
      createdAt
    }
  }
`

export const DELETE_USERS = gql`
  mutation DeleteUsers($where: [UserWhereUniqueInput!]!) {
    deleteUsers(where: $where) {
      id
      name
      email
      isAdmin
      showCMS
      showEsignature
      postsCount
      createdAt
    }
  }
`
