import "./css/loanProduct.css"
import banner from "../images/qa/banner.png"
import banner768 from "../images/qa/banner768.png"
import banner1440 from "../images/qa/banner1440.png"
import banner1920 from "../images/qa/banner1920.png"
import banner2560 from "../images/qa/banner2560.png"
import css from "./css/qa.module.css"
import { HashLink } from "react-router-hash-link"
import { useEffect } from "react"
import QaTabs from "../compoients/QaTabs"
import useDeviceQueries from "../hooks/useDeviceQueries"
import { useMediaQuery } from "react-responsive"

const QA = () => {
  const {
    isDesktop,
    isTablet,
    isMobile,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
  } = useDeviceQueries()

  const list = ["Section 1", "Section 2", "Section 3", "Section 4", "Section 5"]

  const backgroundImage = isMobile
    ? banner1440
    : isTablet
    ? banner768
    : isDesktop1440
    ? banner1440
    : isDesktop1920
    ? banner1920
    : isDesktop2560
    ? banner2560
    : banner1440 // 在没有其他条件匹配时的默认背景
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className="loanProduct-container">
      <div
        className="normal-banner"
        style={{ backgroundImage: `url(${backgroundImage}` }}
      >
        {/* <h1 style={{ color: "white", marginTop: "-2rem" }}>常見問題</h1> */}
      </div>

      <div className={css.container}>
        <div>
          <span className="color4">常見</span>
          <span className="color5">問題</span>
        </div>
        <div className={css.divider}></div>
        <QaTabs />
        <div className="space"></div>
      </div>
    </div>
  )
}

export default QA
