import {
  CREATE_LOAN_PRODUCT,
  DELETE_LOAN_PRODUCT,
  DELETE_LOAN_PRODUCTS,
  GET_LOAN_PRODUCTS,
  UPDATE_LOAN_PRODUCT,
} from "../../../graphql/LoanProduct/LOAN_PRODUCT_GQL"
import {
  CREATE_MENU,
  DELETE_MENU,
  DELETE_MENUS,
  GET_MENUS,
  UPDATE_MENU,
} from "../../../graphql/Menu/MENU_GQL"
import {
  CREATE_HOME,
  DELETE_HOME,
  DELETE_HOMES,
  GET_HOMES,
  UPDATE_HOME,
} from "../../../graphql/Home/HOME_GQL"
import {
  CREATE_META,
  DELETE_META,
  DELETE_METAS,
  GET_METAS,
  UPDATE_META,
} from "../../../graphql/Meta/META_GQL"
import {
  CREATE_ABOUT,
  DELETE_ABOUT,
  DELETE_ABOUTS,
  GET_ABOUTS,
  UPDATE_ABOUT,
} from "../../../graphql/About/ABOUT_GQL"
import {
  CREATE_QA,
  DELETE_QA,
  DELETE_QAS,
  GET_QAS,
  UPDATE_QA,
} from "../../../graphql/QA/QA_GQL"

import {
  CREATE_LARGE_AMOUNT,
  DELETE_LARGE_AMOUNT,
  DELETE_LARGE_AMOUNTS,
  GET_LARGE_AMOUNTS,
  UPDATE_LARGE_AMOUNT,
} from "../../../graphql/LargeAmount/LARGE_AMOUNT_GQL"
import {
  CREATE_PROPERTY_LOAN,
  DELETE_PROPERTY_LOAN,
  DELETE_PROPERTY_LOANS,
  GET_PROPERTY_LOANS,
  UPDATE_PROPERTY_LOAN,
} from "../../../graphql/PropertyLoan/PROPERTY_LOAN_GQL"
import {
  CREATE_PERSONALLOAN,
  DELETE_PERSONALLOAN,
  DELETE_PERSONALLOANS,
  GET_PERSONAL_LOANS,
  UPDATE_PERSONALLOAN,
} from "../../../graphql/Personalloan/PERSONNAL_LOAN_GQL"
import { EditListNames } from "./EditListNames"
import {
  CREATE_APPLICATION_FORM,
  DELETE_APPLICATION_FORM,
  DELETE_APPLICATION_FORMS,
  GET_APPLICATION_FORMS,
  GET_APPLICATION_FORMS_ALL,
  UPDATE_APPLICATION_FORM,
} from "../../../graphql/ApplicationForm/APPLICATION_FORM_GQL"
import {
  GET_USERS,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  DELETE_USERS,
} from "../../../graphql/User/USER_GQL"
import {
  CREATE_POST,
  DELETE_POST,
  DELETE_POSTS,
  GET_POSTS,
  UPDATE_POST,
} from "../../../graphql/Post/POST_GQL"

const getDefaultValue = (fieldName) => {
  switch (fieldName) {
    case "name":
    case "email":
    case "description":
    case "category":
    case "inventoryStatus":
    case "phone":
    case "paymentPeriod":
    case "paymentMethod":
    case "workDuration":
    case "idNumber":
    case "address":
    case "label":
    case "url":
    case "title":
    case "titleChinese":
    case "icon1Text":
    case "icon2Text":
    case "icon3Text":
    case "buttonText":
    case "content":
    case "amount":
    case "order":
    case "tags":
    case "tagsCount":
    case "author":
    case "thumbnail":
    case "banner":
      return ""
    case "isAdmin":
    case "showCMS":
    case "showEsignature":
    case "finished":
      return false
    case "createdAt":
    case "updatedAt":
      return new Date().toISOString()
    case "image":
      return null
    default:
      return null
  }
}

const createEmptyList = (fields) => {
  const emptyList = {}
  fields.forEach((field) => {
    const fieldParts = field.field.split(".")
    if (fieldParts.length > 1) {
      // 處理嵌套字段
      const [parentField, childField] = fieldParts
      if (!emptyList[parentField]) {
        emptyList[parentField] = {}
      }
      emptyList[parentField][childField] = getDefaultValue(childField)
    } else {
      // 處理簡單字段
      emptyList[field.field] = getDefaultValue(field.field)
    }
  })
  return emptyList
}

export const getdatalist = (editListName) => {
  switch (editListName) {
    case EditListNames.POSTS:
      const postsFields = [
        { field: "title", header: "Title" },
        { field: "thumbnail.url", header: "Thumbnail" },
        { field: "banner.url", header: "Banner" },
        { field: "content", header: "Content" },
        { field: "order", header: "Order" },
        { field: "author.name", header: "Author" },
        {
          field: "tags",
          header: "Tags",
          render: (rowData) =>
            Array.isArray(rowData.tags)
              ? rowData.tags.map((tag) => tag.name).join(", ")
              : "",
        },
        { field: "createdAt", header: "Created At" },
      ]
      return {
        listName: "Posts",
        create: CREATE_POST,
        query: GET_POSTS,
        update: UPDATE_POST,
        deleteRecord: DELETE_POST,
        deleteRecords: DELETE_POSTS,
        fields: postsFields,
        getEmptyList: () => createEmptyList(postsFields),
      }
    case EditListNames.USERS:
      const usersFields = [
        { field: "name", header: "Name" },
        { field: "email", header: "Email" },
        { field: "password", header: "Password" },
        { field: "isAdmin", header: "Super Admin" },
        { field: "showCMS", header: "Show CMS?" },
        { field: "showEsignature", header: "Show Esignature?" },
        { field: "createdAt", header: "createdAt" },
      ]
      return {
        listName: "Users",
        create: CREATE_USER,
        query: GET_USERS,
        update: UPDATE_USER,
        deleteRecord: DELETE_USER,
        deleteRecords: DELETE_USERS,
        fields: usersFields,
        getEmptyList: () => createEmptyList(usersFields),
      }
    case EditListNames.APPLICATION_FORMS:
      const applicationFormsFields = [
        { field: "referenceNo", header: "Reference No" },
        { field: "createdAt", header: "Created At" },
        { field: "name", header: "Name" },
        { field: "idNumber", header: "ID Number" },
        { field: "phone", header: "Phone" },
        { field: "amount", header: "Amount" },
        { field: "paymentPeriod", header: "Payment Period" },
        { field: "paymentMethod", header: "Payment Method" },
        { field: "comname", header: "Company Name" },
        { field: "salary", header: "Salary" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Application Forms",
        create: CREATE_APPLICATION_FORM,
        query: GET_APPLICATION_FORMS,
        update: UPDATE_APPLICATION_FORM,
        deleteRecord: DELETE_APPLICATION_FORM,
        deleteRecords: DELETE_APPLICATION_FORMS,
        fields: applicationFormsFields,
        getEmptyList: () => createEmptyList(applicationFormsFields),
      }
    case EditListNames.APPLICATION_FORM_REGISTERS:
      const applicationFormRegistersFields = [
        { field: "referenceNo", header: "Reference No" },
        { field: "name", header: "Name" },
        { field: "phone", header: "Phone" },
        { field: "finished", header: "Completed Application" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Application Form Registers",
        create: CREATE_APPLICATION_FORM,
        query: GET_APPLICATION_FORMS_ALL,
        update: UPDATE_APPLICATION_FORM,
        deleteRecord: DELETE_APPLICATION_FORM,
        deleteRecords: DELETE_APPLICATION_FORMS,
        fields: applicationFormRegistersFields,
        getEmptyList: () => createEmptyList(applicationFormRegistersFields),
      }

    case EditListNames.MENUS:
      const menusFields = [
        { field: "label", header: "Label" },
        { field: "url", header: "URL" },
        { field: "order", header: "Order" },
      ]
      return {
        listName: "Menus",
        create: CREATE_MENU,
        query: GET_MENUS,
        update: UPDATE_MENU,
        deleteRecord: DELETE_MENU,
        deleteRecords: DELETE_MENUS,
        fields: menusFields,
        getEmptyList: () => createEmptyList(menusFields),
      }
    case EditListNames.LOAN_PRODUCTS:
      const loanProductsFields = [
        { field: "title", header: "Title" },
        { field: "titleChinese", header: "Title Chinese" },
        { field: "order", header: "Order" },
        { field: "description", header: "Description" },
        { field: "icon1Text", header: "Icon 1 Text" },
        { field: "icon2Text", header: "Icon 2 Text" },
        { field: "icon3Text", header: "Icon 3 Text" },
        { field: "buttonText", header: "Button Text" },
        { field: "url", header: "URL" },
      ]
      return {
        listName: "Loan Products",
        create: CREATE_LOAN_PRODUCT,
        query: GET_LOAN_PRODUCTS,
        update: UPDATE_LOAN_PRODUCT,
        deleteRecord: DELETE_LOAN_PRODUCT,
        deleteRecords: DELETE_LOAN_PRODUCTS,
        fields: loanProductsFields,
        getEmptyList: () => createEmptyList(loanProductsFields),
      }
    case EditListNames.HOMES:
      const homesFields = [
        { field: "label", header: "Label" },
        { field: "content", header: "Content" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Homes",
        create: CREATE_HOME,
        query: GET_HOMES,
        update: UPDATE_HOME,
        deleteRecord: DELETE_HOME,
        deleteRecords: DELETE_HOMES,
        fields: homesFields,
        getEmptyList: () => createEmptyList(homesFields),
      }
    case EditListNames.METAS:
      const metasFields = [
        { field: "label", header: "Label" },
        { field: "content", header: "Content" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Metas",
        create: CREATE_META,
        query: GET_METAS,
        update: UPDATE_META,
        deleteRecord: DELETE_META,
        deleteRecords: DELETE_METAS,
        fields: metasFields,
        getEmptyList: () => createEmptyList(metasFields),
      }
    case EditListNames.ABOUTS:
      const aboutsFields = [
        { field: "label", header: "Label" },
        { field: "content", header: "Content" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Abouts",
        create: CREATE_ABOUT,
        query: GET_ABOUTS,
        update: UPDATE_ABOUT,
        deleteRecord: DELETE_ABOUT,
        deleteRecords: DELETE_ABOUTS,
        fields: aboutsFields,
        getEmptyList: () => createEmptyList(aboutsFields),
      }
    case EditListNames.QAS:
      const qasFields = [
        { field: "question", header: "Question" },
        { field: "answer", header: "Answer" },
        { field: "order", header: "Order" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "QAs",
        create: CREATE_QA,
        query: GET_QAS,
        update: UPDATE_QA,
        deleteRecord: DELETE_QA,
        deleteRecords: DELETE_QAS,
        fields: qasFields,
        getEmptyList: () => createEmptyList(qasFields),
      }
    case EditListNames.LARGE_AMOUNTS:
      const largeAmountsFields = [
        { field: "label", header: "Label" },
        { field: "content", header: "Content" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Large Amounts",
        create: CREATE_LARGE_AMOUNT,
        query: GET_LARGE_AMOUNTS,
        update: UPDATE_LARGE_AMOUNT,
        deleteRecord: DELETE_LARGE_AMOUNT,
        deleteRecords: DELETE_LARGE_AMOUNTS,
        fields: largeAmountsFields,
        getEmptyList: () => createEmptyList(largeAmountsFields),
      }
    case EditListNames.PROPERTY_LOANS:
      const propertyLoansFields = [
        { field: "label", header: "Label" },
        { field: "content", header: "Content" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Property Loans",
        create: CREATE_PROPERTY_LOAN,
        query: GET_PROPERTY_LOANS,
        update: UPDATE_PROPERTY_LOAN,
        deleteRecord: DELETE_PROPERTY_LOAN,
        deleteRecords: DELETE_PROPERTY_LOANS,
        fields: propertyLoansFields,
        getEmptyList: () => createEmptyList(propertyLoansFields),
      }
    case EditListNames.PERSONAL_LOANS:
      const personalLoansFields = [
        { field: "label", header: "Label" },
        { field: "content", header: "Content" },
        { field: "createdAt", header: "Created At" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Personal Loans",
        create: CREATE_PERSONALLOAN,
        query: GET_PERSONAL_LOANS,
        update: UPDATE_PERSONALLOAN,
        deleteRecord: DELETE_PERSONALLOAN,
        deleteRecords: DELETE_PERSONALLOANS,
        fields: personalLoansFields,
        getEmptyList: () => createEmptyList(personalLoansFields),
      }
    default:
      const defaultFields = [
        { field: "referenceNo", header: "Reference No" },
        { field: "createdAt", header: "Created At" },
        { field: "name", header: "Name" },
        { field: "idNumber", header: "ID Number" },
        { field: "phone", header: "Phone" },
        { field: "amount", header: "Amount" },
        { field: "paymentPeriod", header: "Payment Period" },
        { field: "paymentMethod", header: "Payment Method" },
        { field: "salary", header: "Salary" },
        { field: "updatedAt", header: "Updated At" },
      ]
      return {
        listName: "Application Forms",
        create: CREATE_APPLICATION_FORM,
        query: GET_APPLICATION_FORMS,
        update: UPDATE_APPLICATION_FORM,
        deleteRecord: DELETE_APPLICATION_FORM,
        deleteRecords: DELETE_APPLICATION_FORMS,
        fields: defaultFields,
        getEmptyList: () => createEmptyList(defaultFields),
      }
  }
}
