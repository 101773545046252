// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAPstxwoT6tKQ5j3JitjZczGfKDTdv8y28",
  authDomain: "moving-up-service.firebaseapp.com",
  projectId: "moving-up-service",
  storageBucket: "moving-up-service.appspot.com",
  messagingSenderId: "30717105597",
  appId: "1:30717105597:web:a4bfdfe0ab21701d4f2ed4",
  measurementId: "G-Y481GN5DHB",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export default app
// const analytics = getAnalytics(app)
