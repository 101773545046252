import css from "./css/footer.module.css"
import logo from "../images/footer/foot_logo.png"
import wtsapp_logo from "../images/icon/whatsapp.png"
import fb_logo from "../images/icon/fb.png"
import insta_logo from "../images/icon/instagram.png"
import { HashLink } from "react-router-hash-link"
import { useMediaQuery } from "react-responsive"
import { Form, useNavigate } from "react-router-dom"
import { FormDatatext } from "../hooks/useFormatDatatext"
import { useQuery } from "@apollo/client"
import { GET_METAS } from "../graphql/Meta/META_GQL"
import { useEffect, useState } from "react"
import { findContentByLabel } from "../utils/findContentByLabel"
import { Paths } from "../utils/Paths"
import { wrapText } from "../utils/wrapText"
import useDeviceQueries from "../hooks/useDeviceQueries"

const Footer = () => {
  const {
    isDesktop,
    isTablet,
    isLaptop,
    isMobile,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
  } = useDeviceQueries()

  let metasData = useQuery(GET_METAS)
  metasData = metasData?.data?.metas

  const navigate = useNavigate()

  const license = findContentByLabel(metasData, "license")
  const reportHotLine = findContentByLabel(metasData, "reportHotline")
  const hotline = findContentByLabel(metasData, "hotline")
  const whatsappNum = findContentByLabel(metasData, "whatsappNum")
  const facebookLink = findContentByLabel(metasData, "facebookLink")
  const address = findContentByLabel(metasData, "address")
  const address_eng = findContentByLabel(metasData, "address_eng")
  const footer_remarks = findContentByLabel(metasData, "footer_remarks")

  const advice_slogan = findContentByLabel(metasData, "advice_slogan")

  const phone = ""

  function formatPhoneNumber(number) {
    if (!number || typeof number !== "string") {
      return "" // or return a default value or handle this case differently
    }
    return number.slice(0, 4) + "-" + number.slice(4)
  }

  return (
    <>
      {isDesktop || isTablet || isLaptop ? (
        <div className={css.container}>
          <div className={css.section1_container}>
            <div className={css.column1}>
              <a>
                <img
                  src={logo}
                  alt="logo"
                  className={css.logo}
                  onClick={() => {
                    navigate("/")
                  }}
                />
              </a>
              <br />
              <h2>{advice_slogan}</h2>
              <h2>放債人牌照號碼：{license}</h2>
              <p>查詢/投訴熱線：{hotline}</p>
              <p>Whatsapp: {whatsappNum}</p>
              <div className="d-flex">
                地址：
                {FormDatatext(address)}
              </div>
              {FormDatatext(address_eng)}
              <br />
              {wrapText(footer_remarks)}
            </div>

            <div className={css.column2}>
              <div className={css.page_link}>
                <span className="pe-3">|</span>
                <HashLink smooth to={Paths.personalLoan}>
                  <span>私人貸款</span>
                </HashLink>
                <span className="pe-3 ps-3">|</span>
                <HashLink smooth to={Paths.largeAmount}>
                  <span>大額清數</span>
                </HashLink>
                <span className="pe-3 ps-3">|</span>
                <HashLink smooth to={Paths.porpertyLoan}>
                  <span>業主貸款</span>
                </HashLink>
                <span className="pe-3 ps-3">|</span>
                <HashLink smooth to={Paths.lendersOrdinance}>
                  <span>放債人條例</span>
                </HashLink>
                <span className="pe-3 ps-3">|</span>
                <HashLink smooth to={Paths.privacyPolicy}>
                  <span>隱私政策</span>
                </HashLink>
                <span className="pe-3 ps-3">|</span>
                <HashLink smooth to={Paths.disclaimer}>
                  <span>免責聲明</span>
                </HashLink>
                <span className="pe-3 ps-3">|</span>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <a href={facebookLink} target="_blank">
                  <img
                    src={fb_logo}
                    alt="facebooklogo"
                    className={css.social_logo}
                  />
                </a>
                <img
                  src={insta_logo}
                  alt="instagramlogo"
                  className={css.social_logo}
                />
                <a
                  href={`https://api.whatsapp.com/send/?phone=852${whatsappNum}`}
                  target="_blank"
                >
                  <img
                    src={wtsapp_logo}
                    alt="whatsapplogo"
                    className={css.social_logo}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3 ">
            <hr className={css.divider} />
          </div>
          <div className="d-flex flex-column align-items-center">
            <h1 className="mt-3 mb-5">{advice_slogan}</h1>
          </div>
        </div>
      ) : (
        <div className={css.container_mobile}>
          <div className={css.section1_container_mobile}>
            <img
              src={logo}
              alt=""
              className={css.logo}
              onClick={() => {
                navigate("/")
              }}
            />
            <div className={css.page_link_mobile}>
              <HashLink smooth to={Paths.personalLoan}>
                <span>私人貸款</span>
              </HashLink>
              <HashLink smooth to={Paths.largeAmount}>
                <span>大額清數</span>
              </HashLink>
              <HashLink smooth to={Paths.porpertyLoan}>
                <span>業主貸款</span>
              </HashLink>

              <HashLink smooth to={Paths.lendersOrdinance}>
                <span>放債人條例</span>
              </HashLink>

              <HashLink smooth to={Paths.privacyPolicy}>
                <span>隱私政策</span>
              </HashLink>

              <HashLink smooth to={Paths.disclaimer}>
                <span>免責聲明</span>
              </HashLink>
            </div>
            <p className="p-1">{address}</p>
            <p className="p-1">{address_eng}</p>
            <div style={{ fontSize: "12px" }}>{wrapText(footer_remarks)}</div>
            <br />
            <p>
              查詢/投訴熱線：
              <a href={`tel:+852${hotline}`}>{formatPhoneNumber(hotline)}</a>
            </p>
            <p>放債人牌照號碼：{license}</p>
            <div className={css.social_logo_wrapper}>
              <a
                href={`https://api.whatsapp.com/send/?phone=852${whatsappNum}`}
              >
                <img
                  src={wtsapp_logo}
                  alt="whatsapplogo"
                  className={`${css.social_logo_mobile} me-2`}
                />
              </a>
              <a href={facebookLink}>
                <img
                  src={fb_logo}
                  alt="facebooklogo"
                  className={css.social_logo_mobile}
                />
              </a>
            </div>

            <hr className={css.divider} />
            <div className="d-flex flex-column align-items-center">
              <h1 className="mt-3" style={{ fontSize: "1.5rem" }}>
                {advice_slogan}
              </h1>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer
