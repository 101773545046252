import "./App.css"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom"
import { useEffect, useState } from "react"

import Home from "./all_pages/Home"
import PrivateLoan from "./all_pages/PrivateLoan"
import Blog from "./all_pages/Blog"
import ErrorPage from "./all_pages/ErrorPage"
import PropertyLoan from "./all_pages/PropertyLoan"
import Profile from "./all_pages/Profile"
import AboutUs from "./all_pages/AboutUs"
import QA from "./all_pages/QA"
import Thankyou from "./all_pages/Thankyou"
import BlogPostPage from "./all_pages/BlogPostPage"
import LargeAmount from "./all_pages/LargeAmount"
import ApplyLogin from "./all_pages/ApplyLogin"
import AdminLogin from "./all_pages/AdminLogin"
import PrivacyPolicy from "./all_pages/PrivacyPolicy"
import Disclaimer from "./all_pages/Disclaimer"
import LendersOrdinance from "./all_pages/LendersOrdinance"

//backend
import Dashboard from "./all_pages/backend/Dashboard/Dashboard"
import AdminServices from "./all_pages/backend/AdminServices"
import UpdateUser from "./all_pages/backend/UpdateUser"

import TopMenu from "./compoients/TopMenu"

import Footer from "./compoients/Footer"

//signature
import SignUp from "./signature/components/SignUp/SignUp"
import AdminDashBoard from "./signature/components/AdminDashboard/AdminDashBoard"
import ClientsList from "./signature/components/ClientsList/ClientsList"
import ClientDetail from "./signature/components/ClientDetail/ClientDetail"
import UserDashboard from "./signature/components/UserDashboard/UserDashboard"
import DocSign from "./signature/components/DocSign/DocSign"
import ClientSigned from "./signature/components/ClientSigned/ClientSigned"
import ChangePassowrd from "./signature/components/ChangePassword/ChangePassword"

import PageHead from "./compoients/PageHead"
import FloatBtnList from "./compoients/FloatBtnList"

import ProtectedRoute from "./compoients/ProtectedRoute/ProtectedRoute"
import { AuthContextProvider } from "./AuthContext"

import { Paths } from "./utils/Paths"
import { useQuery } from "@apollo/client"
import { GET_METAS } from "./graphql/Meta/META_GQL"
import { findContentByLabel } from "./utils/findContentByLabel"
import DisplayPdfData from "./all_pages/backend/DisplayPdfData"
import ErrorBoundary from "./compoients/ErrorHandling/ErrorBoundary"
import ServiceError from "./compoients/ErrorHandling/ServiceError"
import UploadFile from "./all_pages/backend/EditView/UploadFile"
import GoogleTagManager from "./compoients/GoogleTagManager"
import GoogleAdsConversion from "./compoients/GoogleAdsConversion"
import { MetaPixel } from "./compoients/MetaPixel"
import LoanCalculationPage from "./all_pages/LoanCalculationPage"

// import BottomNav from "./compoients/BottomNav/BottomNav"

//end signature

function App() {
  const { loading, error, data } = useQuery(GET_METAS)
  const [metasData, setMetasData] = useState([])

  useEffect(() => {
    if (data && data.metas) {
      setMetasData(data.metas)
    }
  }, [data])

  const getMetaData = (dataItem, label) => {
    let metaData
    if (Array.isArray(dataItem)) {
      metaData = dataItem.find((item) => item.label === label)
    } else if (dataItem && dataItem.label === label) {
      metaData = dataItem
    }

    return {
      title: metaData?.metaTitle,
      description: metaData?.metaDescription,
      keywords: metaData?.metaKeywords,
    }
  }

  const homeMetaData = getMetaData(data?.home, "home")
  const applyMetaData = getMetaData(data?.apply, "apply")
  const thankyouMetaData = getMetaData(data?.thankyou, "thankyou")
  const privateloanMetaData = getMetaData(data?.privateloan, "privateloan")

  const creditcardMetaData = getMetaData(data?.creditcard, "creditcard")
  const mortgageMetaData = getMetaData(data?.mortgage, "mortgage")

  const blogMetaData = getMetaData(data?.blog, "blog")
  const qaMetaData = getMetaData(data?.qa, "qa")

  const GtagID = findContentByLabel(data?.metas, "GtagID")
  const GtagLabel = findContentByLabel(data?.metas, "GtagLabel")
  const MetaPixelScript = findContentByLabel(data?.metas, "MetaPixelScript")
  const MetaPixelNoScript = findContentByLabel(data?.metas, "MetaPixelNoScript")

  return (
    <Router>
      <AuthContextProvider>
        {/* <ErrorBoundary> */}
        <div className="app-container">
          <FloatBtnList />
          <GoogleTagManager id={GtagID} />
          <GoogleAdsConversion id={GtagID} label={GtagLabel} />
          <MetaPixel
            MetaPixelScript={MetaPixelScript}
            MetaPixelNoScript={MetaPixelNoScript}
          />
          <Routes>
            <Route
              exact
              path={Paths.home}
              element={
                <PageHead
                  title={homeMetaData.title}
                  description={homeMetaData.description}
                  keywords={homeMetaData.keywords}
                >
                  <TopMenu />
                  <Home />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.userLogin}
              element={
                <PageHead
                  title={applyMetaData.title}
                  description={applyMetaData.description}
                  keywords={applyMetaData.keywords}
                >
                  <TopMenu />
                  <ApplyLogin />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.thankyou}
              element={
                <PageHead
                  title={thankyouMetaData.title}
                  description={thankyouMetaData.description}
                  keywords={thankyouMetaData.keywords}
                >
                  <TopMenu />
                  <Thankyou />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.blog}
              element={
                <PageHead
                  title={blogMetaData.title}
                  description={blogMetaData.description}
                  keywords={blogMetaData.keywords}
                >
                  <TopMenu />
                  <Blog />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={`${Paths.blog}/:postId`}
              element={
                <PageHead
                  title={blogMetaData.title}
                  description={blogMetaData.description}
                  keywords={blogMetaData.keywords}
                >
                  <TopMenu />
                  <BlogPostPage />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.personalLoan}
              element={
                <PageHead
                  title={privateloanMetaData.title}
                  description={privateloanMetaData.description}
                  keywords={privateloanMetaData.keywords}
                >
                  <TopMenu />
                  <PrivateLoan />
                  <Footer />
                </PageHead>
              }
            />

            <Route
              path={Paths.largeAmount}
              element={
                <PageHead
                  title={creditcardMetaData.title}
                  description={creditcardMetaData.description}
                  keywords={creditcardMetaData.keywords}
                >
                  <TopMenu />
                  <LargeAmount />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.porpertyLoan}
              element={
                <PageHead
                  title={mortgageMetaData.title}
                  description={mortgageMetaData.description}
                  keywords={mortgageMetaData.keywords}
                >
                  <TopMenu />
                  <PropertyLoan />
                  <Footer />
                </PageHead>
              }
            />

            <Route
              path={Paths.aboutUs}
              element={
                <PageHead>
                  <TopMenu />
                  <AboutUs />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.qa}
              element={
                <PageHead
                  title={qaMetaData.title}
                  description={qaMetaData.description}
                  keywords={qaMetaData.keywords}
                >
                  <TopMenu />
                  <QA />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.privacyPolicy}
              element={
                <PageHead>
                  <TopMenu />
                  <PrivacyPolicy data={metasData} />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.disclaimer}
              element={
                <PageHead>
                  <TopMenu />
                  <Disclaimer data={metasData} />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.lendersOrdinance}
              element={
                <PageHead>
                  <TopMenu />
                  <LendersOrdinance data={metasData} />
                  <Footer />
                </PageHead>
              }
            />

            {/* ************************ Admin Backend***************** */}
            <Route
              path={Paths.adminLogin}
              element={
                <PageHead>
                  <TopMenu />
                  <AdminLogin />
                  <Footer />
                </PageHead>
              }
            />
            <Route
              path={Paths.calculationTool}
              element={
                <PageHead>
                  <TopMenu />
                  <LoanCalculationPage />
                  <Footer />
                </PageHead>
              }
            />

            <Route element={<ProtectedRoute role="admin" redirectPath="/" />}>
              <Route path="/admin/uploadfile" element={<UploadFile />} />

              <Route exact path={Paths.admin} element={<AdminServices />} />
              <Route exact path={Paths.cmsDashboard} element={<Dashboard />} />
              <Route
                exact
                path={Paths.esignDashboard}
                element={<AdminDashBoard />}
              />
              <Route exact path={Paths.esignSignUp} element={<SignUp />} />
              <Route
                exact
                path={`${Paths.esignChangePassword}:id`}
                element={<ChangePassowrd />}
              />
              <Route
                exact
                path={Paths.esignClientList}
                element={<ClientsList />}
              />
              <Route
                exact
                path={`${Paths.esignClientDetail}:id`}
                element={<ClientDetail />}
              />
              <Route
                exact
                path={`${Paths.esignClientSigned}:id`}
                element={<ClientSigned />}
              />
              <Route
                exact
                path={"/admin/updateuser"}
                element={<UpdateUser />}
              />

              {/*end signature merge */}
            </Route>
            {/* ************************ End Admin Backend***************** */}
            {/* ************************ user Backend***************** */}
            <Route
              element={
                <ProtectedRoute role="user" redirectPath={Paths.userLogin} />
              }
            >
              <Route
                exact
                path={`${Paths.userDashboard}:id`}
                element={<UserDashboard />}
              />

              <Route
                exact
                path={`${Paths.userDocSign}:filename/:id`}
                element={<DocSign />}
              />
            </Route>

            {/* ************************ end user Backend***************** */}
            <Route path="/profile/:username" element={<Profile />} />

            {/* Error Page */}

            <Route
              path="*"
              element={
                <>
                  <TopMenu />
                  <ServiceError />
                  <Footer />
                </>
              }
            />
            {/* <Route
                path={Paths.serviceError}
                element={
                  <>
                    <TopMenu />
                    <ServiceError />
                    <Footer />
                  </>
                }
              /> */}
          </Routes>
        </div>
        {/* </ErrorBoundary> */}
      </AuthContextProvider>
    </Router>
  )
}

export default App
