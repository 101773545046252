import { gql } from "@apollo/client"

export const GET_LOAN_PRODUCTS = gql`
  query LoanProducts {
    loanProducts {
      id
      title
      titleChinese
      description
      icon1Text
      icon2Text
      icon3Text
      buttonText
      url
      order
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_LOAN_PRODUCTS = gql`
  mutation UpdateLoanProducts($data: [LoanProductUpdateArgs!]!) {
    updateLoanProducts(data: $data) {
      id
      title
      titleChinese
      description
      icon1Text
      icon2Text
      icon3Text
      buttonText
      url
      order
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_LOAN_PRODUCT = gql`
  mutation UpdateLoanProduct(
    $where: LoanProductWhereUniqueInput!
    $data: LoanProductUpdateInput!
  ) {
    updateLoanProduct(where: $where, data: $data) {
      id
      title
      titleChinese
      description
      icon1Text
      icon2Text
      icon3Text
      buttonText
      url
      order
      createdAt
      updatedAt
    }
  }
`

export const CREATE_LOAN_PRODUCT = gql`
  mutation CreateLoanProduct($data: LoanProductCreateInput!) {
    createLoanProduct(data: $data) {
      id
      title
      titleChinese
      description
      icon1Text
      icon2Text
      icon3Text
      buttonText
      url
      order
      createdAt
      updatedAt
    }
  }
`
export const DELETE_LOAN_PRODUCT = gql`
  mutation DeleteLoanProduct($where: LoanProductWhereUniqueInput!) {
    deleteLoanProduct(where: $where) {
      id
      title
      titleChinese
      description
      icon1Text
      icon2Text
      icon3Text
      buttonText
      url
      order
      createdAt
      updatedAt
    }
  }
`

export const DELETE_LOAN_PRODUCTS = gql`
  mutation DeleteLoanProducts($where: [LoanProductWhereUniqueInput!]!) {
    deleteLoanProducts(where: $where) {
      id
      title
      titleChinese
      description
      icon1Text
      icon2Text
      icon3Text
      buttonText
      url
      order
      createdAt
      updatedAt
    }
  }
`
