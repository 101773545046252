import React, { useRef, useState } from "react"
import banner from "../images/aboutus/banner.png"
import banner1440 from "../images/aboutus/banner1440.png"
import banner1920 from "../images/aboutus/banner1920.png"
import banner2560 from "../images/aboutus/banner2560.png"
import banner768 from "../images/aboutus/banner768.png"

import { useMediaQuery } from "react-responsive"
import css from "./css/aboutus.module.css"
import timeline from "../images/aboutus/Timeline.png"
import left_arrow from "../images/compoients/left_arrow.png"
import right_arrow from "../images/compoients/right_arrow.png"
import Carousel from "nuka-carousel"

import bg1 from "../images/aboutus/bg1.png"
import icon1_img from "../images/aboutus/icon1.png"
import icon2_img from "../images/aboutus/icon2.png"
import icon3_img from "../images/aboutus/icon3.png"
import useDeviceQueries from "../hooks/useDeviceQueries"
import { GET_ABOUTS } from "../graphql/About/ABOUT_GQL"
import { wrapText } from "../utils/wrapText"
import { useQuery } from "@apollo/client"
import { findContentByLabel } from "../utils/findContentByLabel"
const AboutUs = () => {
  let ContentData = useQuery(GET_ABOUTS)
  ContentData = ContentData?.data?.abouts

  const {
    isDesktop,
    isTablet,
    isMobile,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
  } = useDeviceQueries()

  const backgroundImage = isMobile
    ? banner1440
    : isTablet
    ? banner768
    : isDesktop1440
    ? banner1440
    : isDesktop1920
    ? banner1920
    : isDesktop2560
    ? banner2560
    : banner1440 // 在没有其他条件匹配时的默认背景

  const info = findContentByLabel(ContentData, "info") || ""
  const icon1Text = findContentByLabel(ContentData, "icon1Text") || ""
  const icon2Text = findContentByLabel(ContentData, "icon2Text") || ""
  const icon3Text = findContentByLabel(ContentData, "icon3Text") || ""
  const title = findContentByLabel(ContentData, "title") || ""
  const title2 = findContentByLabel(ContentData, "title2") || ""

  return (
    <div className="loanProduct-container">
      <div
        className="normal-banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <h1 style={{ color: "white", fontWeight: "600" }}>關於我們</h1>
      </div>
      <div className={css.container}>
        {!isMobile ? (
          <div className={css.background}>
            <div className={css.left_text}>
              <p>{wrapText(info)}</p>
            </div>
            <div className={css.right_text}>
              <h3 className="color4">{title}</h3>
              <span className="color-p1 mb-4">{title2}</span>
              <div className={css.icon_text_wrapper}>
                <div className={css.icon_Text}>
                  <img src={icon1_img} alt={icon1Text} />
                  <span>{icon1Text}</span>
                </div>
                <div className={css.icon_Text}>
                  <img src={icon2_img} alt={icon2Text} />
                  <span>{icon2Text}</span>
                </div>
                <div className={css.icon_Text}>
                  <img src={icon3_img} alt={icon3Text} />
                  <span>{icon3Text}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={css.container}>
            <div className={css.left_text}>
              <p>{wrapText(info)}</p>
            </div>
            <div className={css.right_text}>
              <h3 className="color4">{title}</h3>
              <span className="color-p1 mb-4">{title2}</span>
              <div className={css.icon_text_wrapper}>
                <div className={css.icon_Text}>
                  <img src={icon1_img} alt={icon1Text} />
                  <span>{icon1Text}</span>
                </div>
                <div className={css.icon_Text}>
                  <img src={icon2_img} alt={icon2Text} />
                  <span>{icon2Text}</span>
                </div>
                <div className={css.icon_Text}>
                  <img src={icon3_img} alt={icon3Text} />
                  <span>{icon3Text}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AboutUs
