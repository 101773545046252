import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

import css from "./css/loanProductSwiper.module.css"
import icon1 from "../images/loanProductSwiper/icon1.png"
import icon2 from "../images/loanProductSwiper/icon2.png"
import icon3 from "../images/loanProductSwiper/icon3.png"
import "./css/loanProductSwiper.css"

import pp1 from "../images/loanProductSwiper/people-1.png"
import paper from "../images/loanProductSwiper/personalLoan_anim_paper.png"
import coin from "../images/loanProductSwiper/personalLoan_anim_coin.png"

import bg1 from "../images/loanProductSwiper/bg1.png"
import bg2 from "../images/loanProductSwiper/bg2.png"
import bg3 from "../images/loanProductSwiper/bg3.png"

import pp2 from "../images/loanProductSwiper/people-2.png"
import card from "../images/loanProductSwiper/largeamount_anim_card.png"
import dollar from "../images/loanProductSwiper/largeamount_anim_dollar.png"

import pp3 from "../images/loanProductSwiper/people-3.png"
import calculator from "../images/loanProductSwiper/mortgage_anim_calculator.png"
import "../animation.css"
import { TextWithLargerNumbers } from "./TextWithLargerNumbers"
import { useMediaQuery } from "react-responsive"
import useDeviceQueries from "../hooks/useDeviceQueries"

const LoanProductSwiper = ({ data, pageYOffset, isScrollUp }) => {
  const { isDesktop, isTablet, isMobile, isLaptop } = useDeviceQueries()

  gsap.registerPlugin(MotionPathPlugin)

  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()
  const ref5 = useRef()
  const ref6 = useRef()
  const ref7 = useRef()
  const privateloan_ref = useRef()
  const largeamount_ref = useRef()
  const mortgage_ref = useRef()

  const ani1Ref1 = useRef()
  const ani1Ref2 = useRef()
  const ani1Ref3 = useRef()
  const ani1Ref4 = useRef()

  const ani2Ref1 = useRef()
  const ani2Ref2 = useRef()
  const ani2Ref3 = useRef()

  const ani3Ref1 = useRef()
  const ani3Ref2 = useRef()

  const [hasAnimated1, setHasAnimated1] = useState(false)
  const [hasLeftRange1, setHasLeftRange1] = useState(false)
  const [hasAnimated2, setHasAnimated2] = useState(false)
  const [hasLeftRange2, setHasLeftRange2] = useState(false)
  const [hasAnimated3, setHasAnimated3] = useState(false)
  const [hasLeftRange3, setHasLeftRange3] = useState(false)

  function privateloan_anim() {
    gsap.set(ref1.current, {
      x: -800,
      opacity: 1,
    })
    gsap.to(ref1.current, {
      duration: 0.4,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref2.current, {
      x: -900,
      opacity: 1,
    })
    gsap.to(ref2.current, {
      duration: 0.5,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref3.current, {
      x: -1000,
      opacity: 1,
    })
    gsap.to(ref3.current, {
      duration: 0.6,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref4.current, { visibility: "hidden" })
    gsap.set(ref5.current, { visibility: "hidden" })
    gsap.set(ref6.current, { visibility: "hidden" })
    gsap.set(ref7.current, { visibility: "hidden" })
    gsap.to(ref4.current, { visibility: "visible", duration: 0.5, delay: 0.5 })
    gsap.to(ref5.current, { visibility: "visible", duration: 0.5, delay: 0.6 })
    gsap.to(ref6.current, { visibility: "visible", duration: 0.5, delay: 0.7 })
    gsap.to(ref7.current, { visibility: "visible", duration: 0.5, delay: 0.8 })

    /*************************************** */
    gsap.set(ani1Ref1.current, {
      rotation: 20,
    })
    gsap.to(ani1Ref1.current, {
      duration: 0.5,
      ease: "none",
      rotation: -103,
      transformOrigin: "center center",
      onStart: function () {
        ani1Ref1.current.src = bg1
        ani1Ref2.current.style.display = "block"
        ani1Ref3.current.style.display = "block"
        ani1Ref4.current.style.display = "block"
      },
    })
    gsap.set(ani1Ref2.current, {
      x: 0,
      y: 0,
    })
    gsap.to(ani1Ref2.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -300, y: 270 },
          { x: -160, y: 400 },
        ],
        autoRotate: false,
      },
    })
    gsap.set(ani1Ref3.current, {
      x: 0,
      y: 0,
    })

    gsap.to(ani1Ref3.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      transformOrigin: "center center",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -284, y: 71 },
        ],
        autoRotate: false,
      },
    })

    gsap.set(ani1Ref4.current, {
      x: 0,
      y: 0,
    })

    gsap.to(ani1Ref4.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      transformOrigin: "center center",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -400, y: 220 },
          { x: 20, y: 520 },
        ],
        autoRotate: false,
      },
    })
  }

  function largeamount_anim() {
    gsap.set(ref1.current, {
      x: -800,
      opacity: 1,
    })
    gsap.to(ref1.current, {
      duration: 0.4,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref2.current, {
      x: -900,
      opacity: 1,
    })
    gsap.to(ref2.current, {
      duration: 0.5,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref3.current, {
      x: -1000,
      opacity: 1,
    })
    gsap.to(ref3.current, {
      duration: 0.6,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref4.current, { visibility: "hidden" })
    gsap.set(ref5.current, { visibility: "hidden" })
    gsap.set(ref6.current, { visibility: "hidden" })
    gsap.set(ref7.current, { visibility: "hidden" })
    gsap.to(ref4.current, { visibility: "visible", duration: 0.5, delay: 0.5 })
    gsap.to(ref5.current, { visibility: "visible", duration: 0.5, delay: 0.6 })
    gsap.to(ref6.current, { visibility: "visible", duration: 0.5, delay: 0.7 })
    gsap.to(ref7.current, { visibility: "visible", duration: 0.5, delay: 0.8 })

    /*************************************** */

    gsap.set(ani1Ref1.current, {
      rotation: -80,
      opacity: 0.5,
    })

    gsap.to(ani1Ref1.current, {
      duration: 0.5,
      ease: "none",
      rotation: -160,
      opacity: 1,
      transformOrigin: "center center",
      onStart: function () {
        ani1Ref1.current.src = bg2
        ani2Ref1.current.style.display = "block"
        ani2Ref2.current.style.display = "block"
        ani2Ref3.current.style.display = "block"
      },
    })
    if (!isScrollUp) {
      gsap.set(ani1Ref2.current, {
        x: -200,
        y: 400,
      })
      gsap.to(ani1Ref2.current, {
        duration: 0.5,
        ease: "none",
        overwrite: "auto",
        motionPath: {
          path: [
            { x: -200, y: 400 },
            { x: 300, y: 780 },
            { x: 400, y: 900 },
          ],
          autoRotate: false,
        },
      })

      gsap.set(ani1Ref3.current, {
        x: 0,
        y: 0,
      })
      gsap.to(ani1Ref3.current, {
        duration: 0.5,
        ease: "none",
        overwrite: "auto",
        transformOrigin: "center center",
        motionPath: {
          path: [
            { x: 0, y: 0 },
            { x: 0, y: 800 },
          ],
          autoRotate: false,
        },
      })
      gsap.set(ani1Ref4.current, { x: 0, y: 520 })
      gsap.to(ani1Ref4.current, {
        duration: 0.5,
        ease: "none",
        overwrite: "auto",
        transformOrigin: "center center",
        motionPath: {
          path: [
            { x: 20, y: 520 },
            { x: 200, y: 800 },
          ],
          autoRotate: false,
        },
        onComplete: function () {
          ani1Ref2.current.style.display = "none"
          ani1Ref3.current.style.display = "none"
          ani1Ref4.current.style.display = "none"
        },
      })
    }
    /********** */
    gsap.set(ani2Ref1.current, {
      x: 0,
      y: 0,
    })
    gsap.to(ani2Ref1.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -110, y: 450 },
        ],
        autoRotate: false,
      },
    })

    gsap.set(ani2Ref2.current, {
      x: 0,
      y: 0,
    })
    gsap.to(ani2Ref2.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -300, y: 100 },
        ],
        autoRotate: false,
      },
    })
    gsap.set(ani2Ref3.current, {
      x: 0,
      y: 0,
    })

    const tl2 = gsap.timeline()

    tl2.set(ani2Ref3.current, {
      x: 0,
      y: 0,
    })
    tl2.to(ani2Ref3.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -275, y: 275 },
          { x: 0, y: 540 },
        ],
        autoRotate: false,
      },
    })
    //let the object  up and down shaking and loop
    tl2.to(ani2Ref3.current, {
      duration: 0.5,
      ease: "none",
      yoyo: true,
      repeat: -1,
      y: 530,
    })
  }

  function mortgage_anim() {
    gsap.set(ref1.current, {
      x: -800,
      opacity: 1,
    })
    gsap.to(ref1.current, {
      duration: 0.4,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref2.current, {
      x: -900,
      opacity: 1,
    })
    gsap.to(ref2.current, {
      duration: 0.5,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref3.current, {
      x: -1000,
      opacity: 1,
    })
    gsap.to(ref3.current, {
      duration: 0.6,
      x: 0,
      opacity: 1,
      ease: "none",
    })
    gsap.set(ref4.current, { visibility: "hidden" })
    gsap.set(ref5.current, { visibility: "hidden" })
    gsap.set(ref6.current, { visibility: "hidden" })
    gsap.set(ref7.current, { visibility: "hidden" })
    gsap.to(ref4.current, { visibility: "visible", duration: 0.5, delay: 0.5 })
    gsap.to(ref5.current, { visibility: "visible", duration: 0.5, delay: 0.6 })
    gsap.to(ref6.current, { visibility: "visible", duration: 0.5, delay: 0.7 })
    gsap.to(ref7.current, { visibility: "visible", duration: 0.5, delay: 0.8 })

    /*************************************** */
    gsap.set(ani1Ref1.current, {
      rotation: 90,
      opacity: 0.5,
    })

    gsap.to(ani1Ref1.current, {
      duration: 0.5,
      ease: "none",
      rotation: 0,
      opacity: 1,
      transformOrigin: "center center",
      onStart: function () {
        ani1Ref1.current.src = bg3
      },
    })

    gsap.set(ani2Ref1.current, {
      x: -110,
      y: 450,
    })
    gsap.to(ani2Ref1.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: -110, y: 450 },
          { x: -60, y: 500 },
          { x: -10, y: 550 },
          { x: 40, y: 600 },
          { x: 600, y: 600 },
        ],
        autoRotate: false,
      },
    })

    gsap.set(ani2Ref2.current, {
      x: -300,
      y: 100,
    })
    gsap.to(ani2Ref2.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: -300, y: 100 },
          { x: -250, y: 400 },
          { x: -200, y: 450 },
          { x: -150, y: 500 },
          { x: -100, y: 550 },
          { x: -50, y: 600 },
          { x: 300, y: 600 },
        ],
        autoRotate: false,
      },
    })

    gsap.set(ani2Ref3.current, {
      x: 0,
      y: 540,
    })
    gsap.to(ani2Ref3.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: 0, y: 540 },
          { x: 50, y: 590 },
          { x: 100, y: 640 },
          { x: 150, y: 690 },
          { x: 200, y: 740 },
          { x: 300, y: 790 },
        ],
        autoRotate: false,
      },
      onComplete: function () {
        ani2Ref1.current.style.display = "none"
        ani2Ref2.current.style.display = "none"
        ani2Ref3.current.style.display = "none"
      },
    })

    /******************************** */

    gsap.set(ani3Ref1.current, {
      x: 0,
      y: 0,
    })
    gsap.to(ani3Ref1.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -80, y: 350 },
        ],
        autoRotate: false,
      },
    })

    const tl1 = gsap.timeline()
    tl1.set(ani3Ref2.current, {
      x: 0,
      y: 0,
    })
    tl1.to(ani3Ref2.current, {
      duration: 0.5,
      ease: "none",
      overwrite: "auto",
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: -100, y: 200 },
          { x: -50, y: 300 },
          { x: 150, y: 480 },
        ],
        autoRotate: false,
      },
    })
    tl1.to(ani3Ref2.current, {
      duration: 0.5,
      ease: "none",
      yoyo: true,
      repeat: -1,
      y: 470,
    })
  }

  useEffect(() => {
    if (isMobile && !hasAnimated1 && pageYOffset <= 1200) {
      setTimeout(() => {
        privateloan_anim()
      }, 1200)
      setHasAnimated1(true)
    }
    if (
      (isDesktop || isTablet || isLaptop) &&
      !hasAnimated1 &&
      pageYOffset >= 0 &&
      pageYOffset <= 1200
    ) {
      console.log("run privateloan_anim")
      privateloan_anim()
      setHasAnimated1(true)
    } else if (hasAnimated1 && (pageYOffset < 0 || pageYOffset > 1200)) {
      setHasLeftRange1(true)
    } else if (hasLeftRange1 && pageYOffset >= 0 && pageYOffset <= 1200) {
      privateloan_anim()
      setHasAnimated1(true)
      setHasLeftRange1(false)
    }
  }, [pageYOffset, hasAnimated1, hasLeftRange1])

  useEffect(() => {
    if (!hasAnimated2 && pageYOffset >= 1201 && pageYOffset <= 1500) {
      largeamount_ref.current.style.display = "block"
      largeamount_anim()
      setHasAnimated2(true)
    } else if (hasAnimated2 && (pageYOffset < 1201 || pageYOffset > 1500)) {
      if (isScrollUp) largeamount_ref.current.style.display = "none"
      setHasLeftRange2(true)
    } else if (hasLeftRange2 && pageYOffset >= 1201 && pageYOffset <= 1500) {
      largeamount_ref.current.style.display = "block"
      largeamount_anim()
      setHasAnimated2(false)
      setHasLeftRange2(false)
    }
  }, [pageYOffset, hasAnimated2, hasLeftRange2])

  useEffect(() => {
    if (!hasAnimated3 && pageYOffset >= 1501 && pageYOffset <= 1800) {
      mortgage_ref.current.style.display = "block"

      mortgage_anim()
      setHasAnimated3(true)
    } else if (hasAnimated3 && pageYOffset < 1501) {
      // pageYOffset > 1800
      mortgage_ref.current.style.display = "none"
      setHasLeftRange3(true)
    } else if (hasLeftRange3 && pageYOffset >= 1501 && pageYOffset <= 1800) {
      mortgage_ref.current.style.display = "block"
      if (!isScrollUp) mortgage_anim()
      setHasAnimated3(false)
      setHasLeftRange3(false)
    }
  }, [pageYOffset, hasAnimated3, hasLeftRange3])

  return (
    <>
      {isDesktop || isLaptop ? (
        <div className={css.container}>
          <div className={css.left_container}>
            <h4 ref={ref1} className="hidden-left">
              {data?.title}
            </h4>
            <h1 ref={ref2} className="hidden-left">
              {data?.titleChinese}
            </h1>
            <h4 ref={ref3} className="hidden-left mb-5">
              {data?.description}
            </h4>
            <div ref={ref4} className={`${css.iconText} hidden`}>
              <div>
                <img src={icon1} alt="icon1" />
              </div>

              <div>
                <TextWithLargerNumbers text={data?.icon1Text} />
              </div>
            </div>
            <div ref={ref5} className={`${css.iconText} hidden`}>
              <div>
                <img src={icon2} alt="icon2" />
              </div>
              <div>
                <TextWithLargerNumbers text={data?.icon2Text} />
              </div>
            </div>
            <div ref={ref6} className={`${css.iconText} hidden`}>
              <div>
                <img src={icon3} alt="icon3" />
              </div>
              <div>
                <TextWithLargerNumbers text={data?.icon3Text} />
              </div>
            </div>
            <button
              ref={ref7}
              className="ProductPage_btn hidden"
              style={{ marginTop: "25px" }}
              onClick={() => (window.location.href = data?.url)}
            >
              {data?.buttonText}
            </button>
          </div>
          <div className={css.right_container}>
            <div className="anim_container">
              <div ref={privateloan_ref} className="privateloan_anim_container">
                <img
                  ref={ani1Ref1}
                  className="privateloan_anim_obj1"
                  src={bg1}
                  alt="bg1"
                />
                <img
                  ref={ani1Ref2}
                  className="privateloan_anim_obj2"
                  src={pp1}
                  alt="pp1"
                />
                <img
                  ref={ani1Ref3}
                  className="privateloan_anim_obj3"
                  src={paper}
                  alt="paper"
                />
                <img
                  ref={ani1Ref4}
                  className="privateloan_anim_obj4"
                  src={coin}
                  alt="coin"
                />
              </div>
              {/* ********************************************* */}
              <div ref={largeamount_ref} className="largeamount_anim_container">
                <img
                  ref={ani2Ref1}
                  className="largeamount_anim_obj2"
                  src={pp2}
                  alt="pp2"
                />
                <img
                  ref={ani2Ref2}
                  className="largeamount_anim_obj3"
                  src={card}
                  alt="card"
                />
                <img
                  ref={ani2Ref3}
                  className="largeamount_anim_obj4"
                  src={dollar}
                  alt="dollar"
                />
              </div>
              {/* ********************************************* */}
              <div ref={mortgage_ref} className="mortgage_anim_container">
                <img
                  ref={ani3Ref1}
                  className="mortgage_anim_obj2"
                  src={pp3}
                  alt="pp3"
                />
                <img
                  ref={ani3Ref2}
                  className="mortgage_anim_obj3"
                  src={calculator}
                  alt="calculator"
                />
              </div>
            </div>
          </div>
        </div>
      ) : isMobile || isTablet ? (
        <div className={css.container}>
          <div className={css.right_container}>
            <div className="anim_container">
              <div ref={privateloan_ref} className="privateloan_anim_container">
                <img
                  ref={ani1Ref1}
                  className="privateloan_anim_obj1"
                  src={bg1}
                  alt="bg1"
                />
                <img
                  ref={ani1Ref2}
                  className="privateloan_anim_obj2"
                  src={pp1}
                  alt="pp1"
                />
                <img
                  ref={ani1Ref3}
                  className="privateloan_anim_obj3"
                  src={paper}
                  alt="paper"
                />
                <img
                  ref={ani1Ref4}
                  className="privateloan_anim_obj4"
                  src={coin}
                  alt="coin"
                />
              </div>
              {/* ********************************************* */}
              <div ref={largeamount_ref} className="largeamount_anim_container">
                <img
                  ref={ani2Ref1}
                  className="largeamount_anim_obj2"
                  src={pp2}
                  alt="pp2"
                />
                <img
                  ref={ani2Ref2}
                  className="largeamount_anim_obj3"
                  src={card}
                  alt="card"
                />
                <img
                  ref={ani2Ref3}
                  className="largeamount_anim_obj4"
                  src={dollar}
                  alt="dollar"
                />
              </div>
              {/* ********************************************* */}
              <div ref={mortgage_ref} className="mortgage_anim_container">
                <img
                  ref={ani3Ref1}
                  className="mortgage_anim_obj2"
                  src={pp3}
                  alt="pp3"
                />
                <img
                  ref={ani3Ref2}
                  className="mortgage_anim_obj3"
                  src={calculator}
                  alt="calculator"
                />
              </div>
            </div>
          </div>
          <div className={css.left_container}>
            <h4 ref={ref1} className="hidden-left">
              {data?.title}
            </h4>
            <h1 ref={ref2} className="hidden-left">
              {data?.titleChinese}
            </h1>
            <h4 ref={ref3} className="hidden-left mb-5">
              {data?.description}
            </h4>
            <div ref={ref4} className={`${css.iconText} hidden`}>
              <div>
                <img src={icon1} alt="icon1" />
              </div>

              <div>
                <TextWithLargerNumbers text={data?.icon1Text} />
              </div>
            </div>
            <div ref={ref5} className={`${css.iconText} hidden`}>
              <div>
                <img src={icon2} alt="icon2" />
              </div>
              <div>
                <TextWithLargerNumbers text={data?.icon2Text} />
              </div>
            </div>
            <div ref={ref6} className={`${css.iconText} hidden`}>
              <div>
                <img src={icon3} alt="icon3" />
              </div>
              <div>
                <TextWithLargerNumbers text={data?.icon3Text} />
              </div>
            </div>
            <button
              ref={ref7}
              className="ProductPage_btn hidden"
              style={{ marginTop: "25px" }}
              onClick={() => (window.location.href = data?.url)}
            >
              {data?.buttonText}
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default LoanProductSwiper
