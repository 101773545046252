import { useMediaQuery } from "react-responsive"

const useDeviceQueries = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1025px)" })
  const isLaptop = useMediaQuery({
    query: "(min-width: 851px) and (max-width: 1024px)",
  })
  const isTablet = useMediaQuery({
    query: "(min-width: 601px) and (max-width: 850px)",
  })
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" })
  const isDesktop2560 = useMediaQuery({
    query: "(min-width: 1921px) and (max-width: 2560px)",
  })
  const isDesktop1920 = useMediaQuery({
    query: "(min-width: 1441px) and (max-width: 1920px)",
  })
  const isDesktop1440 = useMediaQuery({
    query: "(min-width: 1025px) and (max-width: 1440px)",
  })

  return {
    isDesktop,
    isTablet,
    isMobile,
    isLaptop,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
  }
}

export default useDeviceQueries
