import useFetch from "./useFetch"

const useSubmitForm = () => {
  const { data, loading, error, refetch } = useFetch(
    process.env.REACT_APP_CRM_BACKEND_ENDPOINT + "/api/submit",
    "POST"
  )

  const submitForm = async (formData) => {
    const response = await refetch({ data: formData })
    return response
  }

  return { data, loading, error, submitForm }
}

export default useSubmitForm
