import css from "./css/showNumberSection.module.css"

const ShowNumberSection = ({ data }) => {
  return (
    <div className={css.container}>
      <div className={css.items_wrapper}>
        <div className={css.item_container}>
          <span>{data.featureBlock1_firstline}</span>
          <h1>{data.featureBlock1_secondLine}</h1>
        </div>
        <div className={css.item_container}>
          <span>{data.featureBlock2_firstline}</span>
          <h1>{data.featureBlock2_secondLine}</h1>
        </div>
        <div className={css.item_container}>
          <span>{data.featureBlock3_firstline}</span>
          <h1>{data.featureBlock3_secondLine}</h1>
        </div>
      </div>
      <span className="mt-3">{data.featureBlock_label}</span>
    </div>
  )
}

export default ShowNumberSection
