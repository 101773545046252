import React, { useEffect, useState } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"

import plus_icon from "../images/qa/plus.png"
import minus_icon from "../images/qa/minus.png"
import qicon from "../images/qa/qicon.png"
import aicon from "../images/qa/aicon.png"
import { wrapText } from "../utils/wrapText"
import { useMediaQuery } from "react-responsive"

import { useQuery } from "@apollo/client"
import { GET_QAS } from "../graphql/QA/QA_GQL"

const RecatAccordion = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 801px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 500px)" })
  const [expanded, setExpanded] = useState(false)

  const { data } = useQuery(GET_QAS)
  const qas = data?.qAS

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const title_style = isDesktop
    ? {
        color: "#212529",
        fontSize: "20px",
        fontWeight: "600",
      }
    : isMobile_small
    ? {
        color: "#212529",
        fontSize: "12px",
        fontWeight: "600",
      }
    : { color: "#212529", fontSize: "16px", fontWeight: "600" }

  const answer_style = isDesktop
    ? {
        color: "#212529",
        fontSize: "20px",
        fontWeight: "500",
        textAlign: "left",
      }
    : isMobile_small
    ? {
        color: "#212529",
        fontSize: "12px",
        fontWeight: "500",
        textAlign: "left",
      }
    : {
        color: "#212529",
        fontSize: "16px",
        fontWeight: "500",
        textAlign: "left",
      }

  return (
    <div>
      {qas?.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${item.order}`}
          onChange={handleChange(`panel${item.order}`)}
        >
          <AccordionSummary
            expandIcon={
              expanded === `panel${item.order}` ? (
                <img
                  src={minus_icon}
                  alt="collapse"
                  style={{ width: "30px" }}
                />
              ) : (
                <img src={plus_icon} alt="expand" style={{ width: "30px" }} />
              )
            }
            aria-controls={`panel${item.order}bh-content`}
            id={`panel${item.order}bh-header`}
          >
            <Typography sx={title_style}>
              <img
                src={qicon}
                alt="question"
                style={{ width: "30px", marginRight: "20px" }}
              />
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex">
              <Typography sx={title_style}>
                <img
                  src={aicon}
                  alt="answer"
                  style={{ width: "30px", marginRight: "20px" }}
                />
              </Typography>

              <div style={answer_style}>{wrapText(item.answer)}</div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default RecatAccordion
