export const Paths = {
  adminLogin: "/admin/login",
  admin: "/admin",
  cmsDashboard: "/admin/cms/dashboard",

  esignDashboard: "/admin/esign/dashboard",
  esignSignUp: "/admin/esign/signup",
  esignClientList: "/admin/esign/client-list",
  esignClientDetail: "/admin/esign/client-detail/",
  esignClientSign: "/admin/esign/client-sign/",
  esignClientSigned: "/admin/esign/client-signed/",
  esignChangePassword: "/admin/esign/change-password/",

  userLogin: "/apply/login",
  userDashboard: "/user/dashboard/",
  userDocSign: "/user/doc-sign/",

  //pages
  home: "/",
  personalLoan: "/personalloan",
  largeAmount: "/largeamount",
  porpertyLoan: "/propertyloan",
  aboutUs: "/aboutus",
  blog: "/blog",
  qa: "/qa",
  thankyou: "/thankyou",
  privacyPolicy: "/privacy-policy",
  disclaimer: "/disclaimer",
  lendersOrdinance: "/lenders-ordinance",
  serviceError: "/service-error",
  calculationTool: "/calculation-tool",
}
