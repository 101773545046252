import { useEffect } from "react"
import "./css/privateLoan.css"
import "./css/loanProduct.css"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import banner from "../images/privateloan/banner.png"
import banner_mob from "../images/privateloan/banner_mob.png"
import banner768 from "../images/privateloan/banner768.png"
import banner1440 from "../images/privateloan/banner1440.png"
import banner1920 from "../images/privateloan/banner1920.png"
import banner2560 from "../images/privateloan/banner2560.png"
import ShowNumberSection from "../compoients/ShowNumberSection"
import FeaturePaperSection from "../compoients/FeaturePaperSection"
import CalculatorSection from "../compoients/CalculatorSection"

import icon1 from "../images/privateloan/icon1.png"
import icon2 from "../images/privateloan/icon2.png"
import icon3 from "../images/privateloan/icon3.png"
import icon4 from "../images/privateloan/icon4.png"
import { wrapText } from "../utils/wrapText"
import { Paths } from "../utils/Paths"
import useDeviceQueries from "../hooks/useDeviceQueries"
import { findContentByLabel } from "../utils/findContentByLabel"
import { useQuery } from "@apollo/client"
import { GET_HOMES } from "../graphql/Home/HOME_GQL"
import { GET_PERSONAL_LOANS } from "../graphql/Personalloan/PERSONNAL_LOAN_GQL"

const PrivateLoan = () => {
  let ContentData = useQuery(GET_HOMES)
  ContentData = ContentData?.data?.homes
  let privateLoanData = useQuery(GET_PERSONAL_LOANS)
  privateLoanData = privateLoanData?.data?.personalLoans

  const {
    isDesktop,
    isTablet,
    isMobile,
    isDesktop2560,
    isDesktop1920,
    isDesktop1440,
  } = useDeviceQueries()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  const navigate = useNavigate()
  const backgroundImage = isMobile
    ? banner_mob
    : isTablet
    ? banner768
    : isDesktop1440
    ? banner1440
    : isDesktop1920
    ? banner1920
    : isDesktop2560
    ? banner2560
    : banner1440 // 在没有其他条件匹配时的默认背景

  const applyBtn = (
    <button
      className="ProductPage_btn mt-5 button_hover_motion2"
      onClick={() => navigate(Paths.userLogin)}
    >
      立即申請 Moving up 私人貸款
    </button>
  )

  const featureBlock1_firstline =
    findContentByLabel(ContentData, "featureBlock1_firstline") || ""
  const featureBlock1_secondLine =
    findContentByLabel(ContentData, "featureBlock1_secondLine_personalLoan") ||
    ""

  const featureBlock2_firstline =
    findContentByLabel(ContentData, "featureBlock2_firstline") || ""
  const featureBlock2_secondLine =
    findContentByLabel(ContentData, "featureBlock2_secondLine_personalLoan") ||
    ""
  const featureBlock3_firstline =
    findContentByLabel(ContentData, "featureBlock3_firstline") || ""
  const featureBlock3_secondLine =
    findContentByLabel(ContentData, "featureBlock3_secondLine") || ""
  const featureBlock_label =
    findContentByLabel(ContentData, "featureBlock_label") || ""
  const bannerText = findContentByLabel(privateLoanData, "bannerText") || ""

  const section3_1_title =
    findContentByLabel(privateLoanData, "section3_1_title") || ""
  const section3_1_desc =
    findContentByLabel(privateLoanData, "section3_1_desc") || ""
  const section3_2_title = findContentByLabel(
    privateLoanData,
    "section3_2_title"
  )
  const section3_2_desc = findContentByLabel(privateLoanData, "section3_2_desc")
  const section3_3_title = findContentByLabel(
    privateLoanData,
    "section3_3_title"
  )
  const section3_3_desc = findContentByLabel(privateLoanData, "section3_3_desc")
  const section3_4_title = findContentByLabel(
    privateLoanData,
    "section3_4_title"
  )
  const section3_4_desc = findContentByLabel(privateLoanData, "section3_4_desc")

  const nubmerSectionData = {
    featureBlock1_firstline: featureBlock1_firstline,
    featureBlock1_secondLine: featureBlock1_secondLine,
    featureBlock2_firstline: featureBlock2_firstline,
    featureBlock2_secondLine: featureBlock2_secondLine,
    featureBlock3_firstline: featureBlock3_firstline,
    featureBlock3_secondLine: featureBlock3_secondLine,
    featureBlock_label: featureBlock_label,
  }

  const data = [
    {
      title: section3_1_title,
      icon: icon1,
      desc: section3_1_desc,
    },
    {
      title: section3_2_title,
      icon: icon2,
      desc: section3_2_desc,
    },
    {
      title: section3_3_title,
      icon: icon3,
      desc: section3_3_desc,
    },
    {
      title: section3_4_title,
      icon: icon4,
      desc: section3_4_desc,
    },
  ]

  return (
    <div className="loanProduct-container">
      <div
        className="loanProduct-banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        {(isDesktop || isTablet) && (
          <div className={`loanProduct-banner-text`}>
            <h1>私人貸款</h1>
            {wrapText(bannerText)}
            {applyBtn}
          </div>
        )}
        {isMobile && (
          <div className="loanProduct-banner-text-mobile mb-5">
            <h1>私人貸款</h1>
            {wrapText(bannerText)}
            <div className="loanProduct-banner-btn-mobile">{applyBtn}</div>
          </div>
        )}
      </div>

      {/* section 2 */}

      <ShowNumberSection data={nubmerSectionData} />

      <div className="d-flex flex-column align-items-center mt-5">
        {!isMobile && <h1 className="color4 mt-5">「Moving up」私人貸款</h1>}

        <FeaturePaperSection data={data} />

        <button
          className="apply_btn mt-5 button_hover_motion2"
          onClick={() => (window.location.href = "/apply")}
        >
          立即申請
        </button>
      </div>

      <CalculatorSection />
      <div className="space"></div>
    </div>
  )
}

export default PrivateLoan
